import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { map, Observable, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthCred } from './models/auth-cred';
import { ControlRoutes } from './routes';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _requestBase = environment.zisApiBaseUrl;
  private _loginTimerSSOMode: boolean = true;
  private _loginTimoutMessage: string = '';
  private _loginTimer = timer(0, 1000);
  private _userName: string = '';
  private _heartbeatTimeoutMsec = 60 * 15 * 1000;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router
  ) {
    //Setup SSO and login inactivity monitors

    this._loginTimer.subscribe(() => {
      if (this._loginTimerSSOMode) {
        if (this.isLoggedIn) {
          this.loginSSO();
        }
      } else {
        if (this.isLoggedIn) {
          this.setCookieHeartbeat();

          let timeoutTime = Date.now() - this._heartbeatTimeoutMsec;
          let lastPing = Number(this.cookieService.get('ping'));

          if (lastPing <= timeoutTime) {
            this.logout();
          }
        } else {
          this.logoutSSO();
        }
      }
    });
  }

  public get isLoggedIn(): boolean {
    if (this.cookieService.check('token')) {
      let heartbeatTimeoutTime = Date.now() - this._heartbeatTimeoutMsec;
      let lastHeartbeat = Number(this.cookieService.get('heartbeat'));

      if (lastHeartbeat <= heartbeatTimeoutTime) {
        this._loginTimoutMessage =
          'For your security you have been logged out due to inactivity';
        this.logout();
        return false;
      }

      let timeoutTime = Date.now() - this._heartbeatTimeoutMsec;
      let lastPing = Number(this.cookieService.get('ping'));
      if (lastPing <= timeoutTime) {
        this._loginTimoutMessage =
          'For your security you have been logged out due to inactivity';
        this.logout();
        return false;
      } else {
        return true;
      }
    } else {
      this._loginTimoutMessage = 'For your security you have been logged out';
      return false;
    }
  }

  public get userName(): string {
    return this._userName;
  }

  public get loginTimoutMessage(): string {
    return this._loginTimoutMessage;
  }

  login(ac: AuthCred): Observable<boolean> {
    return this.http.post(this._requestBase + 'auth/token', ac).pipe(
      map((data: any) => {
        this._loginTimerSSOMode = false;
        this._loginTimoutMessage = '';

        this._userName = data.userName;
        this.setAuthCookie(data.token, data.userName);
        this.setCookieHeartbeat();

        this.router.navigateByUrl('/');

        return true;
      })
    );
  }

  loginSSO() {
    this._loginTimerSSOMode = false;
    this._loginTimoutMessage = '';
    this._userName = this.cookieService.get('userName');
    this.setCookiePing();
    this.setCookieHeartbeat();

    if (this.router.routerState.snapshot.url === '/') {
      this.router.navigateByUrl(ControlRoutes.Documents);
    }
  }

  authPing(): void {
    this.http.put(this._requestBase + 'auth/ping', '').subscribe({
      //error means that the session is over
      error: (error) => {
        console.error('Error:', error);
        this.logoutSSO();
      },
    });
  }

  logout() {
    this.http.delete(this._requestBase + 'auth/logout').subscribe();

    this.logoutSSO();
  }

  logoutSSO() {
    this.cookieService.deleteAll('/', this.setHostDomain(), true, 'Lax');

    this.router.navigateByUrl(ControlRoutes.Login);

    this._userName = '';
    this._loginTimerSSOMode = true;
  }

  setHostDomain(): string {
    return window.location.hostname === 'localhost'
      ? window.location.hostname
      : environment.cookieDomain;
  }

  setAuthCookie(token: string, userName: string) {
    this.cookieService.set(
      'ping',
      Date.now().toString(),
      undefined,
      '/',
      this.setHostDomain(),
      true,
      'Lax'
    );

    this.cookieService.set(
      'token',
      token,
      undefined,
      '/',
      this.setHostDomain(),
      true,
      'Lax'
    );

    this.cookieService.set(
      'userName',
      userName,
      undefined,
      '/',
      this.setHostDomain(),
      true,
      'Lax'
    );

    this.cookieService.set(
      'heartbeat',
      Date.now().toString(),
      undefined,
      '/',
      this.setHostDomain(),
      true,
      'Lax'
    );
  }

  setCookieHeartbeat() {
    this.cookieService.set(
      'heartbeat',
      Date.now().toString(),
      undefined,
      '/',
      this.setHostDomain(),
      true,
      'Lax'
    );
  }

  setCookiePing() {
    this.cookieService.set(
      'ping',
      Date.now().toString(),
      undefined,
      '/',
      this.setHostDomain(),
      true,
      'Lax'
    );
  }
}
