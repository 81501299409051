/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
console.log(`zenith-documents - v${require('../package.json').version}`);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error('Error:', err));
