import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogEntry } from './models/logEntry';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';

export enum LogLevel {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  //level: LogLevel = LogLevel.All;
  logWithDate: boolean = true;
  private _requestBase = environment.zisApiBaseUrl;

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  // private shouldLog(level: LogLevel) : boolean {
  //   let ret: boolean = false;

  //   if(this.level !== LogLevel.Off && level >= this.level) {
  //     ret = true;
  //   }

  //   return ret;
  // }

  trace(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Trace, optionalParams);
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  information(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Information, optionalParams);
  }

  warning(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warning, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  critical(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    if (params.some((p) => typeof p == 'object')) {
      ret = '';
      for (let item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }

    return ret;
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    //if(this.shouldLog(level)) {
    let value: string = '';

    // Build log string
    if (this.logWithDate) {
      value = new Date() + ' - ';
    }
    value += 'Type: ' + LogLevel[level];
    value += ' - Message: ' + JSON.stringify(msg);
    value += ' - Log Data: ' + this.formatParams(params);

    console.log(value);

    var newEntry = new LogEntry();
    newEntry.level = level;
    newEntry.message = msg;
    newEntry.logData = params;

    this.postToAPILog(newEntry);
  }

  private postToAPILog(entry: LogEntry) {
    var location = this._requestBase + 'log';
    console.log(`Posting to logAPI ${location}`);
    console.log(`LogEntry ${JSON.stringify(entry)}`);

    try {
      var req = this.http.post(
        location,
        JSON.stringify(entry),
        this.buildHTTPOptions()
      );
      req.subscribe(
        (res) => console.log('HTTP response:', res),
        (err) => console.error('HTTP error:', err),
        () => console.log('HTTP request completed')
      );
      //throw new Error('An error occurred');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  buildHTTPOptions() {
    var headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.getAuthToken(),
    });
    const httpOptions = {
      headers: headers_object,
    };
    return httpOptions;
  }

  getAuthToken() {
    return this.cookieService.get('token');
  }
}
