import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ControlRoutes } from '../routes';
import { AuthCred } from '../models/auth-cred';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition('void => *, * => void', [animate('300ms ease-in')]),
    ]),
    trigger('fadeSlow', [
      state('void', style({ opacity: 0 })),
      transition('void => *, * => void', [animate('1000ms ease-in')]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  todayDate: Date = new Date();
  errorMessage: string = '';
  submitting: boolean = false;
  timeoutMessage: string = '';
  rememberMe: boolean = false;
  submitVisible: boolean = true;

  faExclamationTriangle = faExclamationTriangle;
  faInfoCircle = faInfoCircle;

  ac: AuthCred = {
    //userName: "test",
    //password: "zenithtest1"
    userName: '',
    password: '',
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.timeoutMessage = this.authService.loginTimoutMessage;

    if (this.authService.isLoggedIn) {
      console.log('SSO already logged in');
      this.authService.loginSSO();
    }

    if (localStorage.getItem('rememberMe') == 'true') {
      this.rememberMe = true;
      let lsName = localStorage.getItem('userName');
      this.ac.userName = lsName || '';
    }
  }

  onSubmit(form: NgForm) {
    this.submitting = true;
    this.submitVisible = false;
    this.errorMessage = '';

    var production: boolean = true;

    this.authService.login(this.ac).subscribe(
      (success) => {
        if (success) {
          this.submitVisible = true;
          this.submitting = false;

          if (this.rememberMe) {
            localStorage.setItem('userName', this.ac.userName);
            localStorage.setItem('rememberMe', String(this.rememberMe));
          } else {
            localStorage.setItem('userName', '');
            localStorage.setItem('rememberMe', 'false');
          }

          this.router.navigateByUrl(ControlRoutes.Documents);
        }
      },
      (err) => {
        this.errorMessage = 'Login Failed';
        this.submitVisible = true;
        this.submitting = false;

        console.error('Error:', err);
      }
    );
  }
}
